/* ================================================================
	Pagination
	================================================================ */

.pagination {
	margin:0;
    padding:0;
    list-style-type: none;
	display: flex;

	a {
		display:block;
		padding:5px 15px;
		background:#eee;

		&:hover, &:focus {
			background:#ddd;
		}
	}

	.active {
		a {
			background:transparent;

			&:hover, &:focus {
				text-decoration:none;
				cursor:default;
			}
		}
	}
}
