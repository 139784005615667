// megamenu
.header__main {
  display: none;
  position: absolute;
  z-index: 99;
  left: 0;
  background: white;
  top: 100%;
  width: 100%;

  &.in {
    display: block;
  }
}

.main__nav {
  height: 100%;
  align-items: center;
  list-style: none;
  padding: 0;
  position: relative;

  a {
    color: $color-navy;
    vertical-align: middle;
  }

  .btn {
    color: $color-invert;
  }
}

.main__nav-container {
  display: none;
  z-index: 9;
}

.main__nav-callout {
  display: none;
}

.main__nav-item {
  position: relative;
  padding: 0.5em 0;
  border-bottom: 1px solid $border-color;

  &:last-child {
    border-bottom: 0;
  }

  &.open .main__nav-container {
    display: block;
  }
}

.main__nav-link,
.main__nav-item-link {
  font-size: 1.125rem;
  padding: 0 0.5em;
}

.main__nav-title {
  margin: 0.5em 0;
}

.main__nav-action {
  margin-top: 1em;
}

.main__nav-image {
  margin-bottom: 1em;
}

.main__sub {
  background: $color-sand;
  border: none;
  display: block;
  float: none;
  margin: 0;
  list-style: none;
  padding: 0.5em 0;
  position: relative;

  li {
    font-size: 1rem;
    padding: 0.3em 0.5em;
  }
}

.main__nav-item.show .main__sub {
  display: block;
}

.navbar-toggle {
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px;
  width: 40px;
  height: 40px;
  border: 2px solid rgba(0, 39, 78, 0.1);
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0 15px 0 10px;

  &:hover {
    background-color: #fff !important;
  }

  div {
    margin-top: 4px;
  }
  .icon-bar {
    background-color: #00274e;
    height: 3px;
    display: block;
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    + .icon-bar {
      margin-top: 4px;
    }
  }
}

.dropdown-toggle {
  position: relative;

  &:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
}

@media (min-width: $bp-l) {
  .header__main {
    display: block !important;
    height: auto !important;
    margin: 0;
    position: static;
  }

  .navbar-toggle {
    display: none;
  }

  .main__nav {
    display: flex;
    flex-flow: row wrap;
  }

  .main__nav-item.open .main__nav-container {
    display: flex;
    margin-top: 1em;
  }

  .main__nav-container {
    background: $white;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
    padding: 1em;
    position: absolute;
    top: 100%;
    left: 0;
    width: fit-content;
  }

  .main__nav-item {
    padding: 0;
    border-bottom: 0;

  }
  li.main__sub-item.main__nav-item--first:first-child{
    a{
      border-bottom: 2px solid $blue-dark;
      &:hover, &:focus {
       text-decoration: none;
     }
    }
  }
.main__nav-item--first{

  li.main__sub-item:first-child a{
    border-bottom: 2px solid $blue-dark;

    &:hover, &:focus {
      text-decoration: none;
    }
  }
}
  .main__sub {
    background: $white;
    flex: 1 60%;

    + .main__nav-callout {
      width: 40%;
    }
  }

  .main__nav-callout {
    display: block;
    padding: 0 1em;
  }
}

@media (min-width: 90em) {
  .main__nav-link,
  .main__nav-item-link {
    font-size: 1.3rem;
    padding: 0 1em;
  }
}
