.global-footer {
  background-color: $color-white;

  a, p, address {
    font-size: 0.875rem;
  }


  .footer__top {
    padding: 1.5em 0;
  }

  .footer__middle {
    border-top: 1px solid #ccd4dc;
    border-bottom: 1px solid #ccd4dc;
    padding: 1.5em 0;
  }

  .footer__bottom {    
    padding: 1.5em 0;
  }

  li > a {
    display: inline-block;
    padding: .4em 0;
    text-decoration: none;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }
  ul {
    margin: 0;
  }

  img {
    max-width: 120px;
    height: auto;
  }

  .footer-social {
    font-size: 1.2em;
  }

  .footer-to-top {
    width: 100%;
    background: #F6F5F1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 9px 0px 11px;
    border-top: 1px solid #CCD4DC;

    .back-to-top {
      color: #00274E;
      cursor: pointer;
      background: none;
      border: none;
      font-weight: 400;
      font-size: 14px;
      
      span {
        margin-left: 5px;
        color: #00274E;
        -webkit-text-stroke: 1px #F6F5F1;
      }
    }
  }

  .footer-title {
    color: #00274E;
    text-transform: uppercase;
    font-family: $font-primary;
    font-weight: $fontWeight-bold;
    font-size: 0.8rem;  
  }

  address {
    color: $color-primary;
    font-style: normal;
    margin-bottom: 2em;

    em {
      margin-right: 1em;
    }
  }

}

@media(min-width: $bp-mw) {

  .global-footer img {
    max-width: 160px;
  }  

  .link-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

