/* ================================================================
=   Lists
=  ================================================================ */

.list {
  margin-top: 2rem;
  margin-bottom: 2rem;

  &.list-card-numbered {
    .list {
      &__heading {
        margin-bottom: 2rem;

        @media (min-width: $bp-mw) {
          margin-bottom: 0;
        }
      }

      &__body {
      }

      &__group {
        margin: 0;
        padding: 0;
        list-style: none;
        counter-reset: list-counter;

        li:nth-child(4) {
          @media (min-width: $bp-mw) {
            break-before: column;
            border-top: none;
          }
        }
      }

      &__item {
        counter-increment: list-counter;
        position: relative;

        &:before {
          content: counter(list-counter) ".";
          color: $color-primary;
          background: $blue-light-alt;
          font-size: 1.255rem;
          font-family: $font-heading;
          text-align: center;
          font-weight: $fontWeight-bold;
          width: 3rem;
          height: 3rem;
          margin-bottom: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;

          @media (min-width: $bp-mw) {
            font-size: 2.5rem;
            width: 5rem;
            height: 5rem;
            position: absolute;
            left: 1.5rem;
          }
        }

        &:not(:first-child) {
          margin-top: 1.75rem;
          padding-top: 3rem;
          border-top: 1px solid $blue-light-washed;

          @media (min-width: $bp-mw) {
            margin-top: 0;
            padding-top: 0;
            border-top: none;
            border-left: 1px solid $blue-light-washed;
          }
        }
      }
      &__item-inner {
        @media (min-width: $bp-mw) {
          padding-left: 8rem;
        }
      }

      &__item-title {
        font-family: $font-primary;
      }

      &__item-content {
      }
    }
  }
  &.list-card-numbered--two-columns,
  &.list-card-numbered--one-column {
    .list {
      &__heading {
        margin-bottom: 2rem;

        @media (min-width: $bp-mw) {
          margin-bottom: 0rem;
        }
      }

      &__title {
      }

      &__group {
        padding-left: 0.938rem;
        padding-right: 0.938rem;
      }

      &__item {
        display: flex;
        align-items: center;
        min-height: 3rem;

        &:before {
          width: 3rem;
          height: 3rem;
          margin-top: 0.125rem;
          margin-bottom: 0;
          flex: 0 1 auto;
          left: 0;

          @media (min-width: $bp-mw) {
            width: 5rem;
            height: 5rem;
          }
        }

        &:not(:first-child) {
          margin-top: 1rem;
          padding-top: 1rem;
          border-top: 1px solid $blue-light-washed;
          border-bottom: none;
          border-left: none;
        }

        @media (min-width: $bp-mw) {
          min-height: 6rem;

          &:last-child {
            min-height: 5rem;
          }

          &:not(:first-child) {
            border-left: none;
          }

          &.mid-item {
            break-before: column;
            border-top: none;
          }
        }
      }

      &__item-inner {
        width: calc(100% - 4rem);
        margin-left: auto;
        flex: 0 1 auto;

        @media (min-width: $bp-mw) {
          width: auto;
          margin-left: 0;
          padding-left: 6rem;
        }
      }

      &__item-title {
        font-size: 1rem;
        margin-bottom: 0;
      }

      &__item-content {
      }
    }
  }

  &.list-card-numbered--two-columns {
    .list {
      &__group {
        padding-left: 0.938rem;
        padding-right: 0.938rem;

        @media (min-width: $bp-mw) {
          column-count: 2;
          column-gap: 1rem;
        }
      }
    }
  }

  &.list-expandable-card {
    .list {
      // pseudo column width
      $listColWidth: calc(50% - 1rem);

      &__inner {
        position: relative;

        
        @media (min-width: $bp-mw) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 2em;
        }
      }

      &__title {
        width: 100%;
        grid-column-start: 1;
      }

      &__summary {
        width: 100%;
        margin-bottom: 3rem;
        grid-column-start: 1;
      }

      &__item-heading {
        width: 100%;
        grid-column-start: 1;
        margin-bottom: 0;

        &.panel-trigger {
          cursor: pointer;
        }

        + div + div {
          margin-top: 1rem;
          padding-top: 1rem;
          border-top: 1px solid $blue-light-washed;
        }

        .list__item-icon {
          background: $blue-light-alt;
          width: 3rem;
          height: 3rem;
          min-width: 20px;
          min-height: 20px;
          margin-right: 1rem;
          position: relative;
          border-radius: 50%;

          @media (min-width: $bp-mw) {
            background: $white;
            width: 20px;
            height: 20px;
            border: 2px solid $color-navy;
          }

          &:before,
          &:after {
            content: " ";
            background: $color-navy;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3D(-50%, -50%, 0);
            transition: all 0.2s ease-in-out;

            @media (min-width: $bp-mw) {
              background: $color-navy;
            }
          }

          &:before {
            width: 14px;
            height: 2px;
            @media (min-width: $bp-mw) {
              width: 10px;
            }
          }

          &:after {
            width: 2px;
            height: 14px;

            @media (min-width: $bp-mw) {
              height: 10px;
            }
          }
        }

        &.active {
          border-bottom: 0;

          .list__item-icon {
            &:before,
            &:after {
              background: $color-navy;
              transform: translate3D(-50%, -50%, 0) rotate(45deg);

              @media (min-width: $bp-mw) {
                background: $body-bg;
              }
            }

            @media (min-width: $bp-mw) {
              background: $color-navy;
            }
          }
        }
      }

      &__item-body {
        width: 100%;
        position: static;
        order: 0;
        top: 0;
        right: 0;
        height: 100%;
        grid-row: 1/ span 1000;

        @media (min-width: $bp-mw) {
          grid-column: 2;
        }
      }
      &__item-content {

        height: auto;
        padding: 1rem;
        max-height: 0;
        visibility: hidden;
        overflow: hidden;

        @media (min-width: $bp-mw) {
          background: $blue-light-alt;
          max-height: 100%;
        }
        ul.list-content, ol.list-content{
          margin-bottom: 0;
          padding-bottom: 10px;
        }
      }

      &__item-title {
        font-size: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &:hover,
        &:focus {
          text-decoration: underline;
        }

        @media (min-width: $bp-mw) {
          font-size: 1.5rem;
        }
      }

      &__item-content {
        height: auto;
        padding: 1rem;
        max-height: 0;
        visibility: hidden;
        overflow: hidden;

        @media (min-width: $bp-mw) {
          background: $blue-light-alt;
          max-height: 100%;
        }

      }

      &__item-content-inner {
        display: block;
        padding: 1.5rem;
      }
    }

    .panel-content.active {
      .list__item-content {
        height: 100%;
        max-height: 100%;
        visibility: visible;
        position: relative;
        z-index: 100;
      }
    }
  }
}
ol,
ul {
  &.list-content {
    ul,
    ol {
      @include x-rem(padding-bottom, 5px);

      li {
        @include x-rem(margin-top, 10px);
        margin-bottom: 0;
      }
    }

    li {
      margin-bottom: 0.8rem;
    }
  }
}
