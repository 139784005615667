




/*Sliders
------------------------------------*/
/*Main Parallax Sldier*/
// .da-slide h2 i {
// 	background:url(../../plugins/parallax-slider/img/bg-heading.png) repeat;
// }

/*Sequence Parallax Sldier*/
.sequence-inner {
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#fff), to(#defce7));
  background: -webkit-linear-gradient(#fff, #defce7);
  background: -moz-linear-gradient(#fff, #defce7);
  background: -ms-linear-gradient(#fff, #defce7);
  background: -o-linear-gradient(#fff, #defce7);
  background: linear-gradient(#fff, #defce7)
}

#sequence-theme h2 {
	background:rgba(114, 192, 44, 0.8);
}

#sequence-theme .info p {
	background:rgba(114, 192, 44, 0.8);
}


/*Service
------------------------------------*/
.service .service-icon {
	color:#1e3673;	
}

/*Service Blocks*/
.service-alternative .service:hover { 
	background:#1e3673;
} 

/*Thumbnail (Recent Work)
------------------------------------*/
.thumbnail-style h3 a:hover {
	color:#1e3673;
}

.thumbnail-style a.btn-more {
	background:#1e3673;
}
.thumbnail-style a.btn-more:hover {
	box-shadow:0 0 0 2px #ab0033;
}

/*Carousel (Recent Work)*/
.recent-work a:hover span {
	border-bottom:solid 2px #1e3673;
}

.bx-wrapper .bx-prev {
	background-image: url(../../plugins/bxslider/images/controls.png);
}
.bx-wrapper .bx-next {
	background-image: url(../../plugins/bxslider/images/controls.png);
}

/*Typography
------------------------------------*/

/*Blockquote*/
blockquote:hover {
	border-left-color:#1e3673; 
}

.hero {
	border-left-color: #1e3673;
}

/*Carousel
------------------------------------*/
.carousel-arrow a.carousel-control:hover {
	color: #1e3673;
}

/*Blog Posts
------------------------------------*/
.posts .dl-horizontal a:hover { 
	color:#1e3673; 
}

.posts .dl-horizontal:hover dt img,
.posts .dl-horizontal:hover dd a { 
	color: #1e3673;
	border-color: #1e3673 !important;
}

/*Post Comment*/
.post-comment h3, 
.blog-item .media h3,
.blog-item .media h4.media-heading span a {
	color: #1e3673;
}

/*Tabs
------------------------------------*/
/*Tabs v1*/
.tab-v1 .nav-tabs { 
	border-bottom: solid 2px #1e3673; 	
}

.tab-v1 .nav-tabs > .active > a, 
.tab-v1 .nav-tabs > .active > a:hover, 
.tab-v1 .nav-tabs > .active > a:focus { 
	background: #1e3673; 
}

.tab-v1 .nav-tabs > li > a:hover { 
	background: #1e3673; 
}

/*Tabs v2*/
.tab-v2 .nav-tabs li.active a {
	border-top: solid 2px #1e3673;
}

/*Tabs v3*/
.tab-v3 .nav-pills li a:hover,
.tab-v3 .nav-pills li.active a {
	background: #1e3673;
	border: solid 1px #ab0033;
}

/*Accardion
------------------------------------*/
.acc-home a.active,
.acc-home a.accordion-toggle:hover { 
	color:#1e3673; 
}
.acc-home .collapse.in { 
	border-bottom:solid 1px #1e3673; 
}

/*Testimonials
------------------------------------*/
.testimonials .testimonial-info {
	color: #1e3673;
}

.testimonials .carousel-arrow i:hover {
	background: #1e3673;
}

/*Info Blocks
------------------------------------*/
.info-blocks:hover i.icon-info-blocks {
	color: #1e3673;
}

/*Breadcrumb
------------------------------------*/
.breadcrumb li.active,
.breadcrumb li a:hover {
	color:#1e3673;
}

/*About Page
------------------------------------*/
.team .thumbnail-style:hover h3 a {
	color:#1e3673 !important;
}

/*Social Icons*/
.team ul.team-socail li i:hover {
	background: #1e3673;
}

/*Pricing Page
------------------------------------*/
/*Pricing Head*/
.pricing:hover h4 {
	color:#55c931;
}

.pricing-head h3 {
	background:#55c931;
	border-bottom:solid 1px #41b91c;
}
.pricing-head h4 {
	color:#999;
	background:#fbfef2;
	border-bottom:solid 1px #f5f9e7;
}
	
/*Pricing Content*/
.pricing-content li {
	border-bottom:solid 1px #f5f9e7;
}
.pricing-content li i {
	color:#1e3673;
}

/*Pricing Footer*/
.pricing-footer a,
.pricing-footer button {
	background:#1e3673;
}
.pricing-footer a:hover,
.pricing-footer button:hover {
	background:#40ba1a;
}

/*Priceing Active*/
.price-active h4 {
	color:#55c931;
}

.no-space-pricing .price-active .pricing-head h4,
.no-space-pricing .pricing:hover .pricing-head h4 {
	color:#55c931;
}

/*Right Sidebar
------------------------------------*/
/*Right Sidebar*/
.who li i,
.who li:hover i, 
.who li:hover a { 
	color:#1e3673; 
}

/*Privacy Page
------------------------------------*/
.privacy a:hover {
	color:#1e3673; 
}

/*Portfolio Page
------------------------------------*/
/*Portfolio v1*/
.view a.info:hover {
	background: #1e3673;
}

/*Portfolio v2*/
.sorting-block .sorting-nav li.active {
	color: #1e3673;
	border-bottom: solid 1px #1e3673;
}

.sorting-block .sorting-grid li a:hover span.sorting-cover {
	background: #1e3673;
}

/*Blog Page
------------------------------------*/
.blog h3 {
	color:#1e3673;
}
.blog li a:hover {
	color:#1e3673;
}

/*Blog Tags*/
ul.blog-tags a:hover {
	background: #1e3673;
}

.blog-post-tags ul.blog-tags a:hover {
    background: none repeat scroll 0 0 #1e3673;
}

/*Blog Photos*/
.blog-photos li img:hover {
	box-shadow: 0 0 0 2px #1e3673;
}

/*Blog Latest Tweets*/
.blog-twitter .blog-twitter-inner:hover {
	border-color: #1e3673;
	border-top-color: #1e3673;	
}
.blog-twitter .blog-twitter-inner:hover:after {
	border-top-color: #1e3673;	
}

.blog-twitter .blog-twitter-inner a {
	color: #1e3673;
}

/*Blog Item Page
------------------------------------*/
.blog-item h4.media-heading span a {
	color:#1e3673;
}

/*Coming Soon Page
------------------------------------*/
.coming-soon-border {
	border-top: solid 3px #1e3673;
}

/*Search Page
------------------------------------*/
.booking-blocks p a {
	color: #1e3673;
}

/*Icons Page
------------------------------------*/
.icon-page li:hover { 
	color:#1e3673;
}

/*Glyphicons*/
.glyphicons-demo a:hover {
	color: #1e3673;
	text-decoration: none;
}

/*Magazine Page
------------------------------------*/
/*Magazine News*/
.magazine-news .by-author strong {
	color: #1e3673;
}

.magazine-news a.read-more {
	color: #1e3673;
}

/*Magazine Mini News*/
.magazine-mini-news .post-author strong {
	color: #1e3673;
}

.news-read-more i {
	background: #1e3673;
}

/*Sidebar Features*/
.magazine-page h3 a:hover {
	color: #1e3673;
}

/*Page Features
------------------------------------*/
/*Tag Boxes v1*/
.tag-box-v1 {
	border-top: solid 2px #1e3673;
}

/*Tag Boxes v2*/
.tag-box-v2 {
	border-left: solid 2px #1e3673;
}

/*Tag Boxes v7*/
.tag-box-v7 {
	border-bottom: solid 2px #1e3673;
}

/*Font Awesome Icon Page Style*/
.fa-icons li:hover { 
   color: #1e3673;
}

.fa-icons li:hover i {
   background: #1e3673; 
}

/*GLYPHICONS Icons Page Style*/
.bs-glyphicons li:hover {
   color: #1e3673;
}

/*Navigation
------------------------------------*/
/*Pagination*/
.pagination > .active > a, 
.pagination > .active > span, 
.pagination > .active > a:hover, 
.pagination > .active > span:hover, 
.pagination > .active > a:focus, 
.pagination > .active > span:focus {
   background-color: #1e3673;
   border-color: #1e3673;
}

.pagination li a:hover {
   background: #ab0033;
   border-color: #ab0033;   
}

/*Pager*/
.pager li > a:hover, 
.pager li > a:focus {
   background: #ab0033;
   border-color: #ab0033;   
}

/*Registration and Login Page v2
------------------------------------*/
.reg-block {
	border-top: solid 2px #1e3673;
}

/* For new contacts e-blast page */
.contacts-title {font-weight: bold; text-transform: uppercase; text-decoration: underline; margin: 0;}
.blue {font-weight: bold; color: rgba(33, 82, 135, 1);}
.indent {margin-left: 15px;}/* EDITOR PROPERTIES - PLEASE DON'T DELETE THIS LINE TO AVOID DUPLICATE PROPERTIES */

.script {
    font-family: 'Roboto Slab', sans-serif;
    line-height: 1.37em;
    margin: 0;
    font-weight: bold;
    font-style: italic;
    color: #164d86;
}
.mt1 {margin-top: 10px !important;}
.mb1 {margin-bottom: 10px !important;}
.video-75 {border-radius: 10px !important; margin: 10px 0; padding: 20px; background-color: rgb(237, 235, 212);}
