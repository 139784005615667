.invisibleCaptcha {
    a.btn-captcha {
        display: none;
        &.active {
            display: inline-block;
        }
    }
    button#captchaButton {
        &.hidden {
            display: none;
        }
    }
}