/* ================================================================
   Button Styles
   ================================================================ */

.btn,
.btn-u,
.btn-u-primary {
  display: inline-block;
  padding: 0.8em 1.2em;
  background: #00274e;
  border: 0;
  border-radius: 5px;
  font-family: $font-primary;
  font-weight: $fontWeight-semibold;
  color: $color-invert;
  font-size: 0.875rem;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: all $nav-transition-speed ease-out;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: $color-white;
  }
}

.btn--ghost {
  @extend .button;
  background: none;
  color: var(--primary-color);

  &:hover,
  &:focus {
    background: none;
    color: $color-black;
  }
}

.btn--cta {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: $color-white;

  &:hover,
  &:focus {
    background: $color-white;
    color: var(--primary-color);
  }
}

.btn-primary,
.btn-yellow {
  background: $color-yellow;
  border: 1px solid $color-yellow;
  font-weight: $fontWeight-bold;
  color: $color-navy;

  &:hover,
  &:focus {
    background-color: #d99900;
    border-color: #cc9000;
    box-shadow: 0 0 0 0.2rem rgba(222, 159, 6, 0.5);
    text-decoration: none;
  }
}

.btn-blue {
  background-color: $color-navy;
  border-color: $color-navy;
  font-weight: 700;
  color: $color-invert;

  &:hover,
  &:focus {
    border-color: $color-navy-hover;
  }
}
.btn-secondary,
.btn-hollow {
  background: $transparent;
  font-weight: $fontWeight-bold;
  color: $color-navy;
  border: 2px solid $color-blue-lighter;

  &:hover,
  &:focus {
    background: $color-blue-lighter;
    border: 2px solid $color-blue-light-hover;
    text-decoration: none;
  }
}
.btn-white {
  background-color: $color-invert;
  border-color: $color-invert;
  font-weight: $fontWeight-bold;
  text-transform: uppercase;
  color: $color-blue3;

  &:hover,
  &:focus {
    color: $color-navy-hover2;
    background-color: $color-light-hover;
    border-color: $color-lightgray2;
  }
}
