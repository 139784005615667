/** Cards Start **/
.card {
  text-align: left;
  margin-bottom: $space-sm;

  .banner {
      background-position:center center;
      background-repeat: no-repeat;
      background-size:cover;
      &:after {
          content: " ";
          position: absolute;
          top: 0; left: 0;
          width: 100%; height: 100%;
          background: #23527c;
          opacity: 0;
          transition: .3s ease-in-out;
      }
      &:hover:after { opacity:.5; }
  }
  @media(min-width:$bp-m) {
    margin-bottom: $space-md;
      .banner {
        @include x-rem(height, 320px);
      
      }
  }
}

.card-block {
    padding: 2rem 2.5rem;
  }
  .card-block img {
    @include x-rem(max-width, 160px);
  }
  .card-block__heading {
    margin: 0.2rem 0;
  }
  
  .hero {
    background-size: cover;
    background-position: 20%;
    background-repeat: no-repeat;
    padding: 4rem 0;
    min-height: 26.875rem;
  }
  
  .hero__title {
    color: $blue-dark;
    font-size: 46px;
    font-weight: $fontWeight-bold;
    margin-bottom: 0.3rem;
  
    em {
      display: inline-block;
      position: relative;
      font-style: normal;
  
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: -7px;
        width: 100%;
        height: 7px;
        background: $red;
        border-radius: 100% 10% 100% 5%;  
      }
    }
  }
  .card-icon {
    margin: 2rem 0;
  
    &.showAll .card-icon__item:nth-child(n + 7) {
      display: block;
    }
  }
  
  .card-icon__item {
    background: $body-bg;
    display: block;
    margin-bottom: 1rem;
    padding: 0.2rem;
    width: 100%;
  
    &:nth-child(n + 7) {
      display: none;
    }
  }
  
  .card-icon__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-navy;
    height: 100%;
    padding: 0.8rem;
  }
  
  .card-icon__media {
    display: flex;
    align-items: center;
  }
  
  .card-icon__img,
  .card-icon__hover-img {
    max-width: 70px;
    height: auto;
    margin-right: 1rem;
  }
  
  .card-icon__hover-img {
    display: none;
  }
  
  .card-icon__title {
    font-family: $font-primary;
    font-size: 0.975rem;
    margin: 0 1rem 0 0;
  
    h3 {
      margin: 0;
    }
  }
  
  .card-icon__summary {
    display: none;
    margin-top: 0.5rem;
  }
  
  .card-icon__arrow {
    text-align: right;
  
    .icon {
      fill: $color-navy;
      height: 1.5rem;
      width: 1.5rem;
    }
  }
  
  @media (min-width: $bp-mw) {
    .card-icon {
      display: flex;
      flex-flow: row wrap;
  
      .card-icon__item:nth-child(n + 7) {
        display: block !important;
      }
    }
  
    .card-icon__item {
      display: inline-block;
      margin: 0.3rem;
      min-height: 240px;
  
      &:hover,
      &:focus {
        box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.2);
        text-decoration: none;
        background: $color-dark-sand;
  
        .card-icon__summary {
          display: block;
        }
  
        .card-icon__img {
          opacity: 0;
          position: absolute;
        }
  
        .card-icon__hover-img {
          opacity: 1;
          position: relative;
        }
      }
    }
  
    .card-icon__content {
      display: flex;
      flex-flow: column;
      justify-content: unset;
      align-items: unset;
      padding: 1.5rem;
      transition: 0.3s all linear;
    }
  
    .card-icon__title {
      font-size: 1.25rem;
    }
  
    .card-icon__hover-img {
      display: block;
      opacity: 0;
      position: absolute;
    }
  
    .card-icon__arrow {
      margin-top: auto;
    }
  
    .industries-action {
      display: none;
    }
  }
  
  .hero__text {
    color: $blue-dark;
  }
  
  .hero--dark .hero__text, .hero--dark .hero__title{
    color: $body-bg;
  }
  @media(min-width: $bp-m) {
    .hero {
      background-position: 50%;
      min-height: 37rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
  }
  .hero__text {
    max-width: 50%;
    margin-left: auto;
  }
  }
  @media(min-width: $bp-l-m) {
    .hero { 
      padding: 9rem 0;
    }
  
    .hero__title {
      font-size: 56px;
    }
  
     
    
  }
