.image-gallery-overlay__grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @include x-rem(gap, 20px);

  @media (min-width: $bp-mw) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $bp-l-m) {
    grid-template-columns: repeat(3, 1fr);
  }
 a{
    display: block;
 }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.image-container {
  position: relative;
  padding: 0 4em;
  margin: 2em 0;
}

.image-carousel {
  list-style: none;
  margin: 0;
  padding: 0;
}

.image-carousel__item {
  display: inline-block;
  padding: 0.5em 0.5em 2em 0.5em;
}

.image-carousel__link {
  display: block;
  height: 250px;
  position: relative;
}

.image-carousel__img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.mfp-img {
  max-width: 100% !important;
}

@media(min-width: $bp-l) {
  .image-carousel__link {
    height: 300px;
  }
}