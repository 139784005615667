/* ================================================================
   Typography Styles
   ================================================================ */


   h1, .h1, h2, .h2, h3, .h3 {
    margin-bottom: $headings-margin-bottom;
    font-family:$font-heading;
  }
  
  h1, .h1 { @include responsive-headlines($font-size-h1); }
  h2, .h2 { @include responsive-headlines($font-size-h2); }
  h3, .h3 { @include responsive-headlines($font-size-h3); }
  h4, .h4 { @include responsive-headlines($font-size-h4); font-family: $font-primary }
  h5, .h5 { @include responsive-headlines($font-size-h5);}
  
  a {
    color:var(--primary-color);
    text-decoration:none;
  
    &:hover, &:focus { text-decoration:underline; }
  }
  a:not([class]){
    text-decoration: underline;
    &:hover, &:focus { text-decoration:none; }
  }
  p { margin:0 0 $typography-margin-bottom; }
  
  .list-unstyled {
    list-style:none;
    margin:0;
    padding:0;
  }
  
  // Very simple blockquote styling
  blockquote {
    margin:1em 1.5em; padding-left:1.5em;
    border-left:5px solid hsl(0, 0%, 85%);
  }
  
  .smallest { font-size:0.8em; }
  .smaller { font-size:0.9em; }
  .larger { font-size:1.1em; }
  .largest { font-size:1.2em; }
  
  .bold { font-weight:bold; }
  .italic { font-style:italic; }
  .strike { text-decoration:line-through; }
  
  .nowrap { white-space:nowrap; }
  .important { color:red; font-weight:bold; text-transform:uppercase; }

  article.news-detail > h1.title { margin: 0 0 0.5em;}