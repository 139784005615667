label {
  display: block;
  font-size: 0.875rem;
  font-weight: $fontWeight-semibold;
  text-transform: uppercase;
  margin-bottom: 0.3em;
}

.form-group,
.input-row {
  margin-bottom: 1em;
}

.form-control {
  border-color: $color-navy;
}

.form-control {
  border: 1px solid $color-primary;
  border-radius: 5px;
  height: 36px;
  width: 100%;
}

// required
.required-indicator {
	&:before {
	  content: "*";
	  color: $red;
	}
  }

// Removes the clear button on search inputs
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
