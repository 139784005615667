// top most navbar
.navbar-collapse {
  border-top: 1px solid #e7e7e7;
}

.navbar-nav {
  margin: 0;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;

  &>li {
      position: relative;
      &>a{
        font-weight: $fontWeight-semibold !important;
        @include x-rem(font-size, 12px); 
        color: $color-navy;
        padding: 10px 8px;
        line-height: 1.1;
        border-radius: 4px;
      }

      &.show {
          >a {
              color: $color-navy;
          }
          @media(min-width: $bp-m) {
            >a {
              background-color: transparent;
            }
          }

      }

      &>a {
          display: block;
          color: $color-primary;
          padding: 10px 6px;

          &:hover {
              color: $color-blue;
          }
      }
  }

  @media(min-width: $bp-m) {
      flex-flow: row wrap;
      justify-content: flex-end;
      margin: 0 0 0 10px;

      
      &>a {
        padding: 10px;

    }
  }
}

.header__utility {
  background-color: #F7F6F2;
  padding: 0.5em;
  text-align: right;

  .btn {
    font-size: 0.8rem;
    padding: 0.5em 1em;
  }
}

// logo, main nav
.header__bottom{
  display: flex;  
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1em;
  @extend .navbar;
  border-bottom: 1px solid $border-color;
  border-top: none;
  margin-bottom: 0;
  position: relative;
}

.header__logo img {
  height: auto;
  max-width: 190px;
}

#divText {
  font-size: 0.9rem;
}

#site-main.overlay:before{
  background-color: rgba(51,51,51,.5);
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}
#site-main .page-heading-banner.overlay:before {
  background-color: rgba(51,51,51,.5);
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.nav-li a {
  font-weight: $fontWeight-semibold !important;
  @include x-rem(font-size, 12px); 
  color: $color-navy;
  padding: 10px 8px;
  line-height: 1.1;
  border-radius: 4px;
  text-decoration: none;

  &:hover, &:focus {
    text-decoration: underline;
    color: $color-navy !important;
  }
}


@media(min-width: $bp-mw) {
  .header__logo img {
    max-width: 250px;
  }

  #divText {
    font-size: 1.25rem;
  }
}
 