.pagination {
	margin: 20px 0;
	border-radius: 4px;
	li {
		a {
			display: inline-block;
			padding: 6px 12px;
			border: 1px solid #ddd;
			background-color: transparent;
			&:hover,&:focus {
				background: #eee;
				color: #23527c;
			}
		}
		&.active {
			a {
				background: $color-primary;
				color: $white;
				border-color: $color-primary;
				&:hover,&:focus {
					background: $color-primary;
					color: $white;
				}
			}
		}
	}
	li:first-child {
		a {
			border-radius: 4px 0 0 4px;
		}
	}
	li:last-child {
		a {
			border-radius: 0 4px 4px 0;
		}
	}
}