.a11y-slider,
.a11y-slider-container {
    position: relative
}

.a11y-slider {
    overflow: hidden;
    -webkit-scroll-snap-type: x mandatory;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.a11y-slider::-webkit-scrollbar {
    width: 0;
    height: 0
}

.a11y-slider>* {
    scroll-snap-align: start;
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit
}

.a11y-slider-sr-only {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    text-align: center;
    font-family: inherit;
    font-size: 1rem
}

.a11y-slider-sr-only:focus {
    position: static;
    background: #000;
    color: #fff;
    width: 100%;
    height: auto;
    margin: 0;
    clip: auto;
    padding: 10px
}

.a11y-slider-hide {
    display: none
}

.a11y-slider-scrolling {
    cursor: -webkit-grabbing;
    cursor: grabbing;
    -webkit-scroll-snap-type: none;
    -ms-scroll-snap-type: none;
    scroll-snap-type: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    scroll-behavior: auto
}
.a11y-slider-dots {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
  padding: 0;
  position: relative;
}

.a11y-slider-dots li {
  display: block;
  flex: 1 auto;
  height: 5px;
  padding: 0;
  color: $color-invert;
}

.a11y-slider-dots li button {
  display: block;
  font-size: 0;
  text-indent: -9999px;
  border: none;
  width: 100%;
  height: 100%;

  &.active {
    background: $color-navy;
  }
}

.a11y-slider-prev, .a11y-prev {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}


.a11y-slider-next, .a11y-next {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.news-container, .image-container {
    .a11y-slider-container {
      position: unset;
    }
    .a11y-slider-next , .a11y-slider-prev {
      background:$body-bg;
      border: 2px solid $color-lightgray;
      border-radius: 3px;
      font-size: 0;
      @include x-rem(width, 48px);
      @include x-rem(height, 48px);
      @include x-rem(padding, 3.2px 8px );
  
      &:after {
        content: "\2794";
        display: inline-block;
        @include x-rem(font-size, 24px);
      }
    
      .icon {
        width: 1rem;
        height: 1rem;
      }
    }
  
    .a11y-slider-prev:after { 
      transform: scale(-1, 1);
    }
  
  }