/* ================================================================
    Layouts
================================================================ */

.heading-banner {
    position:relative;
    z-index:1;
    padding:10% 1rem;
    overflow: hidden;
}

.heading-banner__media,
.heading-banner__media img {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    overflow: hidden;

    .sfPageEditor & {
        position:static;
    }
}

.heading-banner__media {
    z-index: -1;
}

.heading-banner__media img {
    object-fit: cover;
}

.heading-banner__content {
    position:relative;
    text-align: center;
    font-size:20px;
    color:#fff;

    * {
        font-size:inherit;
        color:inherit;
    }
}

.sfPageEditor .modal-layout.modal {
    display:block;
    position:static;
    opacity:1;
    overflow: visible;

    .modal-dialog {
        width:auto;
        transform: none;
    }

    .modal-header .close {
        display:none;
    }

    .modal-content {
        box-shadow:none;
    }
}
.sand-bg {
    background: $color-light-sand;
    padding: 2rem 0;
  }
  
  .sand-bg-symbol {
  

    h1,h2,h3 {
      color: $color-navy;
      font-weight: 800;
      margin-bottom: 0.2em;
    }
   
  }
// M accents

.m-accent{
    background: $color-light-sand;
    padding: 2rem 0;
    & > *{
      color: $color-primary;
    }

  &--blue {
    background: url('/ResourcePackages/MCGINS/assets/dist/images/sand-symbol-blue.png') no-repeat, $color-blend-blue;
    background-size: 60% 310%;
    background-blend-mode: luminosity;
    & > *{
      color: $color-invert;
    }
  }
  &--white {
    background: url('/ResourcePackages/MCGINS/assets/dist/images/sand-symbol-white.png') no-repeat, $color-blend-white;
    background-size: 60% 310%;
    background-blend-mode: luminosity;
  }

  &--ultrawhite {
    background: url('/ResourcePackages/MCGINS/assets/dist/images/sand-symbol-white.png') no-repeat, $body-bg;
    background-size: 60% 310%; 
    background-blend-mode: luminosity;
  }
  &--gold { 
    background: url('/ResourcePackages/MCGINS/assets/dist/images/sand-symbol-gold.png') no-repeat, $color-blend-gold;
    background-size: 60% 310%;
    background-blend-mode: luminosity;

  }
  &--light-gold { 
    background: url('/ResourcePackages/MCGINS/assets/dist/images/sand-m-accent.png') no-repeat, $color-light-sand;
    background-size: 60% 310%;
    background-blend-mode: luminosity;  
  }

  &--sand-cropped {
    background: url('/ResourcePackages/MCGINS/assets/dist/images/m-accent-cropped.png') no-repeat;
    background-color: $color-light-sand;
    background-size: cover;
    background-position: top;
  }

  &--right{
    background-position: right;
  }

  &--left{
    background-position: left;
  } 
}
  .white-bg{
    background: $color-sand;
    padding: 2rem 0;
  }
  .sand-bg-symbol--gaig{
    background-image: url('/ResourcePackages/MCGINS/assets/dist/images/sand-symbol.png');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
  }
  
  .navy-bg {
    background:$color-navy2;
    color: $color-invert;
    padding: 2em 0;
  
    a {
      color: $color-invert;
    }
  }
  .tan-bg {
    background:$color-tan; 
    padding: 2em 0;
  }
  .gold-bg {
    background:$color-gold;
    padding: 2em 0;
  }
  
  .v-spacing-s {
    padding:1.25rem 0;
  }
  
  .v-spacing-md {
    padding:1.5rem 0;
  }
  
  .v-spacing-lg {
    padding:3rem 0;
  }
  .v-align-center {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;  
  }
  .align-items-center {
    align-items: center!important;
}
@media(min-width:$bp-m){
    .heading-banner__content {
        @include x-rem(font-size, 40px);
    }
}
@media(min-width: $bp-l-max){
    .v-spacing-s {
      padding:1.5rem 0;
    }
    
    .v-spacing-md {
      padding:3rem 0;
    }
  
    .v-spacing-lg {
      padding:6rem 0;
    }
  }