/* ================================================================
   Page Meta Area
    - Page Tools/ Social Media
   ================================================================ */





/* Page Tools (Print | Email | Share)
   ================================================================ */

.social-share { 
	padding:0;
	text-align:left;

	li { display:inline-block; 
		 padding-left: 15px;
		a { display:block; 
			color: $color-blue2;
			svg { width:30px; height:30px }
			&:hover, &:focus{
				color: $blue-dark2;
			}
		}

		&.share-facebook { 
			svg { fill:#3b5998 } 
		}
		&.share-twitter { 
			svg { fill:#00aced } 
		}
		&.share-linkedin { 
			svg { fill:#007bb6; } 
		}
		&.share-reddit { 
			svg { fill:#ff4500; } 
		}
		&.share-digg { 
			svg {  } 
		}
		&.share-mail { 
			svg {  } 
		}
		&.share-tumblr { 
			svg { fill:#32506d } 
		}
		&.share-googleplus { 
			svg { fill:#dd4b39; } 
		}
		&.share-stumbleupon { 
			svg { fill:#eb4823; } 
		}
		&.share-bloggr { 
			svg { fill:#fb8f3d; } 
		}
	}
}
ul.social-share li:first-of-type {
    padding-left: 5px;
}