.banner {
    border-bottom: 3px solid $red;
    position: relative;
    a {
      display: inline-block;
    }
    h1, h2, h3, h4, h5, p {
      color: $color-invert;
    }
    h1 {
      margin: 0;
      padding: 0;
      text-align: left;
      @include responsive-headlines(4rem);
    }
    ol.breadcrumb {
      background-color: transparent;
      margin: $space-xs 0;
      padding: 0;
      @include border-radius(0);
      > li {
        font-size: $font-size-sm;
        font-weight: $fontWeight-semibold;
        letter-spacing: 0.03rem;
        text-transform: uppercase;
      }
      > li a {
        color: rgba($color-invert, 0.7);
      }
      > li + li:before {
        color: rgba($color-invert, 0.5);
        content: "\f105";
        font-family: $font-family-icons;
        padding: 0 6px 0 4px;
      }
    }
    p {
      @include paragraph-big($font-size-xl);
    }
    .banner-click {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;
      &:hover {
        text-decoration: none;
        color:  $blue-dark2;
      }
    }
    .banner-gradient:after {
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
      @include linear-gradient(rgba(#000, 0.2), rgba(#000, 0.5), 10%, 80%);
      @media (min-width: $bp-m) {
        @include linear-gradient(rgba(#000, 0), rgba(#000, 0.5), 10%, 80%);
      }
    }
    .banner-header {
      padding: $space-md $space-sm;
      position: relative;
      z-index: 2;
      @media (min-width: $bp-m) {
        bottom: 0;
        position: absolute;
        width: 100%;
      }
    }
    &.banner-centered {
      h1, h2, h3, h4, h5, p {
        text-align: center;
      }
    }
  }
  
  section.banner {
    @media (min-width: $bp-m) {
      padding-top: $space-xl;
    }
  }
  