 /** News Start **/
 .news {
   &.featured-articles {
       .banner {
           width: 100%;
           background-repeat: no-repeat;
           background-size: cover;
           background-position: center center;
       }
   }

   &.top-stories {
       .banner {
           height: auto;
       }

       .news-story {
           &+.news-story {
            @include x-rem(margin-top, 45px);
               
           }

           &:first-child .top-media-heading {
            @include x-rem(margin-top, 9.6px);
           }

           &:first-child .media-topic+.top-media-heading {
               margin-top: 0;
           }

           img {
               width: 100%;
           }
       }
   }

   @media(min-width:$bp-m) {
       &.news.featured-articles .banner {
         @include x-rem(height, 430px);
           width: 100%;
       }

       .card .banner {
         @include x-rem(height, 430px);
           width: 100%;
       }
   }

}

.news-story {
   flex-wrap: wrap;
   &.card.banner {
      @include x-rem(height, 220px);
       background-position: center center;
   }
   .text {
       width: 100%;
   }

   @media(min-width:$bp-m) {
       .media-left {
           padding-left: 0;
       }
   }
}

/** News End **/

 
 /** News Carousel **/
 .news-container {
    position: relative;
    @include x-rem(margin, 32px 0);
    @include x-rem(padding, 0 80px);
  }
  
     .news-carousel__item {
      @include x-rem(padding, 8px);
     }
  
     .news-carousel__media {
        @include  x-rem(height, 250px);
     }
  
     .news-carousel__img {
      object-fit: cover;
      height: 100%;
      width: 100%;
     }
  
     .news-carousel__item-title, .news-carousel__item-link {
      @include x-rem(font-size, 32px);
      color: $color-navy;
      font-weight: 600;
     }
  
     .news-carousel__item-category {
      color: $color-blue-light;
      @include x-rem(margin-bottom, 8px);
     }