/* ******************************************************
   NAVIGATION
        - Sitemap styles
****************************************************** */

$sitemap-indent: 20px !default;
$sitemap-heading-bg: $color-primary !default;
$sitemap-heading-bg-hover: darken($color-primary, 10%) !default;
$sitemap-tree-line: mix($color-primary, $body-bg, 50%) !default;
$sitemap-tree-dot: 8px !default;
$grid-gutter-em:1em;

.sitemap__heading {
    display:block;
    padding:0.5em $sitemap-indent; margin-bottom:0.5em;
    color:#fff;
    text-decoration:none;
    background:$sitemap-heading-bg;

    &:hover, &:focus {
        text-decoration:none;
        background:$sitemap-heading-bg-hover;
    }
}

.sitemap { padding:20px 0; }

.sitemap__item { display:block; }

.sitemap__link {
    position:relative;
    display:block;
    padding:4px 0;
    font-weight:bold;
    line-height:1.2em;
}

.sitemap__menu, .sitemap__item-sub {
    margin:0;
    padding:0;
    list-style-type: none;
}

.sitemap > .sitemap__menu > .sitemap__item { margin-bottom:2rem; }

.sitemap > .sitemap__menu > .sitemap__item > .sitemap__link { @extend .sitemap__heading; }

// Level 2
.sitemap .sitemap__item-sub { margin-left:$sitemap-indent; }

// Level 3
.sitemap .sitemap__item-sub .sitemap__item-sub { margin-left:$sitemap-indent/2; }

.sitemap .sitemap__item-sub .sitemap__item-sub .sitemap__link { font-size:0.9rem; }

// Level 4
.sitemap .sitemap__item-sub .sitemap__item-sub .sitemap__item-sub .sitemap__link { font-weight:normal; color:$color-base; }

.sitemap > .sitemap__menu > .sitemap__item > .sitemap__item-sub > .sitemap__item {
    position:relative;

    &:before {
        content:'';
        position:absolute; 
        top:0; 
        left:-$sitemap-indent/2; 
        z-index:2; //above the content, but below the dot
        height:100%; 
        width:1px;
        background: $sitemap-tree-line;
    }

    &:first-child:before {
        top:1em;
        height:100%;
    }

    &:last-child:before { height:$sitemap-tree-dot; }

    &:first-child:last-child:before{ height:0; }

    // 2nd level dots
    & > .sitemap__link:after { 
        content:'';
        position:absolute; 
        top:1em; 
        left:(-$sitemap-indent/2 - $sitemap-tree-dot/2); 
        z-index:3; 
        margin-top:-$sitemap-tree-dot/2 - 2px;
        height:$sitemap-tree-dot; width:$sitemap-tree-dot;
        border-radius:$sitemap-tree-dot;
        background:$sitemap-tree-line;
    }
}

@media(min-width:$bp-m){
    .sitemap__menu {
        display: flex;
        flex-wrap: wrap;
        margin-left:-$grid-gutter-em;
        margin-right:-$grid-gutter-em;
    }

    .sitemap__menu > .sitemap__item { width:50%; padding:0 $grid-gutter-em; }
}

@media(min-width:$bp-mw){
    .sitemap__menu > .sitemap__item { width:33.3333%; }
}

@media(min-width:$bp-l){
    .sitemap__menu > .sitemap__item { width:25%; }
}