$gray-base:                                     #000 !default;
$gray-dark:                                     #333 !default;
$gray-lighter:                                  #eee !default;
//Size

$s-border:                                      1px !default;
$m-border:                                      4px !default;
$l-border:                                      5px !default;
$xl-border:                                     6px !default;
$xxl-border:                                    8px !default;

$xxl-border-radius:                             8px !default;
$round-border-radius:                           50% !default;

$font-size-md:                                  16px !default;


//## Colors

$comments-header--selected-color:               $gray-dark !default;
$comments-list-author-border-color:             #777 !default;

//## Size

$comments-list-author-border-width:             $s-border !default;
$comments-form-min-height:                      90px !default;

$comments-margin-top:                           30px !default;
$comments-loading-margin-bottom:                15px !default;
$comments-count-margin-left:                    10px !default;
$comments-header-margin-bottom:                 30px !default;
$comments-header-h3-margin-right:               20px !default;
$comments-header-a-margin-top:                  5px !default;
$comments-header-a-margin-bottom:               $comments-header-a-margin-top !default;
$comments-list-margin-bottom:                   30px !default;
$comments-list-author-padding-right:            5px !default;
$comments-more-margin-bottom:                   30px !default;
$comments-subscribe-margin-top:                 15px !default;
$comments-subscribe-margin-left:                50px !default;


.comments-header { padding-top:15px; padding-bottom:15px; }

.comments {
  padding: 20px 0; margin-bottom:25px;
  border-bottom: 4px solid #333;
}

.comment {
  padding:25px 0 0;

  .comments-message {
    position: relative;
    padding: 25px; margin-top: 12px;
    background: #eee;

    &:before {
      @include pseudo();
      top: 10px; left: -10px;
      width: 10px; height: 10px;
      border-top: 10px solid transparent; border-bottom: 10px solid transparent; border-right: 10px solid #eee;
    }
  }
}

.comment-add {

  + [data-sf-role="single-comment-template"] { display:none; }
}
