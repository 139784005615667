/* ================================================================
	Accessible Slideshow Styles
================================================================ */

.slideshow__slide {
    position:relative;
    z-index: 1;
    padding:14.1% 5.5rem;
    max-height:500px;
    text-align: center;
}

.slideshow__slide-img {
    position:absolute;
    top:0;
    left:0;
    z-index:-1;
    width:100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size:cover;
    background-position:left top;

    &:after {
        content:'';
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background:rgba(0,0,0,.4);
    }
}

.slideshow__slide-title {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.5;
    text-transform: uppercase;
    color: #fff;
}

.slideshow__slide-caption {
    font-size: 20px;
    font-weight: 400;
    line-height: 60px;
    color: #fff;

    &:after {
        content:'';
        display:block;
        width:70px;
        height:2px;
        margin:20px auto 0;
        background:#fff;
    }
}

.slideshow__slide-actions {
    margin-top:20px;
}

.slideshow {
    .a11y-slider-prev,
    .a11y-slider-next {
        position:absolute;
        top:50%;
        z-index:2;
        width:40px;
        height:40px;
        border:0;
        margin:0;
        padding:0;
        font-size:0;
        color:transparent;
        transform:translateY(-50%);
    }

    .a11y-slider-prev {
        left:10px;
        background: url($images+"/large_left.png") no-Repeat 0 0;

        &:hover, &:focus {
            background-position:bottom left;
        }
    }

    .a11y-slider-next {
        right:10px;
        background: url($images+"/large_right.png") no-Repeat 0 0;

        &:hover, &:focus {
            background-position:bottom right;
        }
    }

    .a11y-slider-autoplay {
        display: none;
    }
}

.slideshow .a11y-slider-dots {
    position:absolute;
    left:0;
    bottom:1rem;
    z-index:2;
    display:flex;
    flex-wrap:wrap;
    align-items: center;
    justify-content: center;
    width:100%;
    margin:0;
    padding:0;
    list-style-type: none;

    li {
        margin-left:3px;

        &:first-child {
            margin-left:0;
        }
    }

    button {
        width:20px;
        height:20px;
        border:0;
        margin:0;
        padding:0;
        background:url($images+"/bullet.png") no-repeat top left;
        font-size:0;
        color:transparent;
        cursor: pointer;

        &:hover, &:focus, &.active {
            background-position:bottom left;
        }
    }
}

@media(min-width:$bp-m){
    .slideshow__slide-title {
        font-size:3.076923076923077rem;
    }
}