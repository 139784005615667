/* ================================================================
    Staff Listing
================================================================ */

.staff {
    display: flex;
    align-items: flex-start;
    border-top:1px solid #ddd;
    padding:1rem 0;

    &:first-child {
        border-top:0;
    }
}

.staff__media {
    max-width:150px;

    & + .staff__body {
        padding-left:50px;
    }
}

.staff__name {
    margin:0 0 10px;
    font-family:sans-serif;
    font-size:13px;
    font-weight:700;
    line-height:normal;
    color:#555;
}

.staff__position {
    margin:10px 0 0;
    font-style:italic;
}

.staff__links {
    display: flex;
    align-items: center;
    margin:10px 0 0 ;
    padding:0;
    list-style-type: none;

    li + li {
        border-left:1px solid #ddd;
        margin-left:5px;
        padding-left:5px;
    }
}

.staff__phone {
    display: inline-block;
    margin:10px 0 0;
}

.staff-list {
    margin:0;
    padding:0;
    list-style-type: none;
}

.staff-list--alt {
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    margin-left:-1rem;
    margin-right:-1rem;

    .staff {
        flex-direction: column;
        width:50%;
        border-top:0;
        padding:0 1rem;
        margin-bottom:1rem;
    }

    .staff__media + .staff__body {
        margin:10px 0 0;
        padding-left:0;
    }
}

@media(min-width:$bp-m){
    .staff-list--alt {
        justify-content: flex-start;

        .staff {
            width:25%;
        }
    }
    .staff-list--alt .staff {
        width:25%;
    }
}