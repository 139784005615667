.media {
    margin: 0 0 $space-sm;
    .media-body,
    .media-left {
      padding-right: $space-sm;
    }
    .media-body p,
    .media-heading {
     @include x-rem(font-size, 14.8px);
      line-height: 1.3;
    }
    .media-heading {
      margin: $space-xs 0;
      font-weight: $fontWeight-bold;
      color: $color-blue2;
      a{
        color: $color-blue2;
        &:hover, &:focus {
        color: $blue-dark2;
        }
      }
      
    }
    .media-left img {
      @media (max-width: $bp-m) {
        @include x-rem(max-width, 80px);
      }
    }
    .media-topic {
      color: $color-gray;
      font-size: $font-size-sm;
      font-weight: $fontWeight-semibold;
      margin: .6rem 0 0;
      text-transform: uppercase;
    }
  }
  
  .media .banner {
    width: 100%;
    .media-heading {
      color: $color-invert;
      font-weight: $fontWeight-light;
      line-height: $line-height-sm;
      margin: 0;
      @include responsive-headlines($font-size-h2);
    }
    .media-topic {
      color: rgba($color-invert, 0.7);
      margin-bottom: .4em;
    }
  }
  
  .media-stacked {
    .media-left {
      display: table-row;
      img {
        display: block;
        height: auto;
        max-width: 100%;
      }
    }
  }
  
  section.media-news {
    .media {
      border-bottom: 1px solid $border-color;
      margin: 0;
      padding: .5rem .9375rem;
    }
    .media-title {
      margin-bottom: 0;
    }
  }
  
  section.media-videos {
    .media {
      margin-bottom: 0;
      @extend .media-stacked;
      @include make-xs-column(6);
    }
  }
  
  .media-title {
    background-color: $color-lightgray;
    font-size: $font-size-xl;
    margin: 0 0 $space-sm;
    padding: .8rem .9375rem;
  
    .card & {
      font-size: $font-size-xs;
    }
  }
  