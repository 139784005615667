/* ================================================================
   Variables
   ================================================================ */

   
@font-face {
  font-family: 'ProximaNova';
  src: url(/ResourcePackages/MCGINS/assets/dist/fonts/ProximaNova-Regular.ttf);
}

@font-face {
  font-family: 'ProximaNovaCond';
  src: url(/ResourcePackages/MCGINS/assets/dist/fonts/ProximaNovaCond-Regular.ttf);
}


@font-face {
  font-family: 'ProximaNovaSemiBold';
  src: url(/ResourcePackages/MCGINS/assets/dist/fonts/ProximaNova-Semibold.ttf);
}

@font-face {
  font-family: 'ProximaNovaBold';
  src: url(/ResourcePackages/MCGINS/assets/dist/fonts/ProximaNova-Bold.ttf);
}

@font-face {
  font-family: 'BerninaSans';
  src: url(/ResourcePackages/MCGINS/assets/dist/fonts/Bernina-Sans-Compressed.otf);
}

@font-face {
  font-family: 'BerninaSansBold';
  src: url(/ResourcePackages/MCGINS/assets/dist/fonts/Bernina-Sans-Compressed-Bold.otf);
}

// Color Variables
$body-bg: #fff;
$color-base: #000;
$color-invert: #fff;
$color-primary: #00274e;
$color-secondary: #ffb400;
$color-black:#000;
$color-white:#fff;
$color-gray: #999999;
$color-lightgray: #f0f0f0; 
$color-lightgray2: #e6e6e6; 
$blue-dark:  #003c69;
$blue-dark2:  #23527c;
$red: #cc0000;

// Colors
$color-blue: #007bff;
$color-blue2: #337ab7;
$color-blue3: #035489;
$color-blue-light:#7694aa;
$color-blue-lighter:#e5edf3;
$color-blue-light-hover:#b9cfdf;
$blue-light-alt:#D6DFF8;
$blue-light-washed:#CCD4DC;
$color-navy: #00274E;
$color-navy2: #001F3E;
$color-navy-hover: #000e1b;
$color-navy-hover2: #212529;
$color-sand: #F7F6F2;
$color-tan: #F6F5F1;
$color-mid-sand: #EEECE4;
$color-dark-sand: #DDDAC8;
$color-light-sand: #CCC7AD;
$color-light-hover: #ececec;
$color-yellow: #FFB400;
$color-yellow2: #d99900;
$color-gold: #FFD266;

// m accents
$color-blend-blue:#00224E;
$color-blend-white:#E7E7E8 ;
$color-blend-gold: #AFA279;

$text-color: $color-navy;

//Borders
$border-color:             		#d8d8d8;


// Typography
$font-primary: "ProximaNova",Arial, sans-serif;
$font-secondary: "BerninaSansBold", Geneva, sans-serif;
$font-heading: $font-secondary;
$font-family-icons: "FontAwesome";

$fontWeight-light: 300;
$fontWeight-normal: 400;
$fontWeight-medium: 500;
$fontWeight-semibold: 600;
$fontWeight-bold: 700;

$font-size-h1:            		3.5rem;
$font-size-h2:            		3rem;
$font-size-h3:            		2.5rem;
$font-size-h4:            		1.25rem;
$font-size-h5:            		1.25rem;
$font-size-h6:            		.75rem;
$font-size-sm:            		0.875rem;
$font-size-xl:            		1.25rem;
$font-size-xs:            		.75rem;

$line-height-base:        		1.4;
$line-height-sm:          		1.2;

$typography-margin-bottom: 1em;

// Breakpoints
// You can convert these at http://pxtoem.com
$bp-s: 20em; // 320px
$bp-s-max: 29.938em; // 479px
$bp-sw: 30em; // 480px
$bp-sw-max: 47.938em; // 767px
$bp-m: 48em; // 768px
$bp-m-max: 59.938em; // 959px
$bp-mw: 60em; // 960px
$bp-mw-max: 71.188em; // 1139px
$bp-l: 72em; // 1140px
$bp-l-m: 75em; // 1200px
$bp-l-max: 81.188em; // 1299px
$bp-xl: 81.25em; // 1300px

$nav-transition-speed: 0.4s;
$transparent: transparent;
$nav-barBackground: $transparent;
$nav-mainLinkBackground: $color-white;
$nav-subLinkBackground: $color-white;


// Grid
$grid-gutter: 20px;
$grid-gutter-em: 1.053em; // Changing grid gutter to em causes a compiling error :/

// Spacing
$space-xl: $grid-gutter*2.5;
$space-xs: $grid-gutter*.3;
$space-lg: $grid-gutter*1.5; 
$space-md: $grid-gutter; 
$space-sm: $grid-gutter*.6; 

// File Variables
$images: "/ResourcePackages/MCGINS/assets/dist/images";
$fonts:"/ResourcePackages/MCGINS/assets/dist/fonts/";